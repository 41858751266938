import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getSignInResponse } from "../../utils/authUtil";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { UpdateVerifyOtp, mobileVerify } from "../../slices/updateMobSlice";
import $ from "jquery";
import OtpInput from "react-otp-input";
import Loading from "../include/Loading";

function UpdateMob() {
  const dispatch = useDispatch();
  let userInfo = getSignInResponse();

  const verificationData = useSelector(
    (state) => state.updateMobile.verificationData
  );

  const verifyOtpData = useSelector(
    (state) => state.updateMobile.verifyOtpData
  );

  const [mobObj, setMobObj] = useState({
    mobNo: "",
    prefixCode:
      userInfo && userInfo.mobile_prefix_code
        ? userInfo.mobile_prefix_code
        : "",
  });

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");

  const handleVerify = async () => {
    if (showOtpInput) {
      // Handle OTP verification logic here
      dispatch(
        UpdateVerifyOtp({ userName: userInfo.username, otp: otp, ...mobObj })
      );
      setShowOtpInput(false);
    } else {
      if (mobObj.mobNo.length < 10) {
        Swal.fire({
          title: "Alert",
          text: "Mobile number should be at least 10 digits.",
          icon: "error",
        });
      } else {
        dispatch(mobileVerify({ ...mobObj, email: userInfo.email }));
        //   $("#UpdateMob").removeClass("open-it");
        //   $("#UpdateMobOtp").addClass("open-it");

        // Call your API here since the mobile number is valid
        // Example: await apiCall(mobObj.mobNo);
        // Replace 'apiCall' with your actual API call function
      }
    }
  };

  useEffect(() => {
    if (verificationData && userInfo && verificationData.username === userInfo.username) {
      setShowOtpInput(true);
    }
  }, [verificationData]);

  useEffect(() => {
    if (verifyOtpData && verifyOtpData.auth_token) {
      window.location.reload();
    }
  }, [verifyOtpData]);

  const isLoading = useSelector((state) => state.updateMobile.isLoading);

  return (
    <div
      className="comman-model comman-side-popup forgot-password-model "
      id="UpdateMob"
    >
      <Loading loading={isLoading} />

      <div className="comman-popup-inner">
        <div className="top-model-header">
          <div className="pop-top-title">
            <h2>Update Mobile Number</h2>
          </div>
          <a
            href="javascript:void(0);"
            className="close_panel"
            onClick={() => setShowOtpInput(false)}
          >
            <i>
              <img src="../../img/close.png" alt="" />
            </i>
          </a>
        </div>
        <div className="popup-model-body">
          <form>
            <div className="forgotimgpt">
              <img src="../img/forgot.png" alt="" />
            </div>
            {showOtpInput ? (
              <div className="form-group">
                <label>Enter your OTP code here</label>
                <OtpInput
                  containerStyle={{ justifyContent: "space-evenly" }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{ width: "5rem", height: "5rem" }}
                />
              </div>
            ) : (
              <div className="form-group">
                <label>Mobile No.</label>
                <PhoneInput
                  country={"za"}
                  value={`${mobObj.prefixCode}${mobObj.mobNo}`}
                  onChange={(value, data) => {
                    setMobObj((prevState) => ({
                      ...prevState,
                      mobNo: value.slice(data.dialCode.length),
                      prefixCode: data.dialCode,
                    }));
                  }}
                  placeholder="+27"
                  inputClass={"form-control"}
                  inputStyle={{ padding: "0px 50px" }}
                />
                {/* <input
                required
                type="tel"
                className="form-control"
                placeholder="Enter Your Email"
                value={mobNo}
                onChange={(e) => setMobNo(e.target.value)}
              /> */}
              </div>
            )}
            <div className="form-group btn_row">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleVerify()}
              >
                {showOtpInput ? "Verify OTP" : "Verify"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="shadowpt clearfix"></div>
    </div>
  );
}

export default UpdateMob;
